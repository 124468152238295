.dark .react-datepicker__header {
    text-align: center;
    background-color: rgb(79 70 229);
    border-bottom: 0px;
    border-top-left-radius: 5;
    border-top-right-radius: 5;
    padding-top: 8px;
    position: relative;
    color: #fff;
}

.dark .react-datepicker__today-button {
    background: #3e82cf;
    border-top: 0px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
    color: #fff
}

.dark .react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: rgb(17 24 39);
    color: #000;
    border: 0px;
    border-radius: 5;
    display: inline-block;
    position: relative;
}

.dark .react-datepicker__day {
    color: #777
}

.dark .react-datepicker__day--selected {
    background-color: #3e82cf;
    color: #fff;
    border-radius: 5;
}

.dark .react-datepicker__current-month {
    /* background-color: #3e82cf; */
    color: #fff;
    /* border-radius: 5; */
}

.dark .react-datepicker__day-name {
    /* background-color: #3e82cf; */
    color: #ccc;
    /* border-radius: 5; */
}