/*
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/22/20, 10:30 AM
 *
 */

/*  src/Title.css  */
.Title {
    /*box-sizing: border-box;*/
    /*width: 100%;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*padding: 1em;*/
    /*margin-bottom: 2em;*/
    /*background-color: rgb(192, 45, 26);*/
    /*color: #fff;*/
    padding-top: 10px;
}

.shimmer {
    text-align: center;
    color: rgba(255,255,255,0.1);
    background: -webkit-gradient(linear, left top, right bottom, from(#222), to(#222), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right bottom, from(#222), to(#222), color-stop(0.5, #fff));
    background: gradient(linear, left top, right bottom, from(#222), to(#222), color-stop(0.5, #fff));
    -webkit-background-size: 125px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 5s;
    -moz-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #222;
}

.dark-theme .shimmer {
    text-align: center;
    color: rgba(137, 255, 184, 0.6);
    background: -webkit-gradient(linear, left top, right bottom, from(#222), to(#222), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right bottom, from(#222), to(#222), color-stop(0.5, #fff));
    background: gradient(linear, left top, right bottom, from(#222), to(#222), color-stop(0.5, #fff));
    -webkit-background-size: 125px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 5s;
    -moz-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #222;
}


@-moz-keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}
@-webkit-keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}
@-o-keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}
@keyframes shimmer {
    0% {
        background-position: top left;
    }
    100% {
        background-position: top right;
    }
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 0.3em;
}
h2 {
    font-size: 1.5rem;
}
h3 {
    font-size: 1.2rem;
} */