.editorClassName2 {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
}

.dark-theme .rdw-option-wrapper  {
    background: #b6b6b6 !important;
    border: #000;
}

.dark-theme .rdw-dropdown-wrapper  {
    background: #4e4e4e !important;
    border: #000;
}
.dark-theme .rdw-image-wrapper  {
    background: #4e4e4e !important;
    border: #000;
}
.dark-theme .rdw-image-modal  {
    background: #4e4e4e !important;
    border: #000;
    box-shadow: none;
}
.dark-theme .rdw-dropdown-optionwrapper  {
    background: #4e4e4e !important;
    border: #000;
}
.dark-theme .rdw-dropdownoption-active   {
    background: #292929 !important;
    border: #000;
}