.ql-editor {
    background-color: #fafafa;
    color: #444;
    font-family: "Gilroy", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42;
    margin: 0;
    padding: 15px;
    ;
}

.dark-theme .ql-editor {
    background-color: #1f2937;
    color: #fafafa;
}

.dark-theme .ql-picker {
    border-color: #444;
    color: #ccc;
}

.ql-toolbar {

    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    ;
}

.ql-toolbar {
    border-color: #ccc !important;
    /* color: #ccc; */
    /* background-color: #1e293b; */
}

.dark-theme .ql-toolbar {
    border-color: #444;
    color: #ccc;
    background-color: #1e293b;
}

.quill {
    /* border: 1px solid #ccc; */
    border-radius: 14px;
    /* margin-bottom: 24px; */
    /* padding: 24px; */
}

.ql-container,
.ql-editor {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    border-color: #ccc;
}


.ql-container.ql-snow {
    border-color: #ccc;
}

.ql-container .ql-snow .dark-theme {
    border-color: #444;
}

.ql-editor .dark-theme {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    border-color: #444;
}

.dark-theme .ql-stroke {
    stroke: #ccc !important;
}

.ql-stroke {
    stroke: #444 !important;
}