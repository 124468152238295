.skeleton-box {
    position: relative;
    overflow: hidden;
    background-color: #e3e9f0;
    /* margin-bottom: 46px !important; */
    border-radius: 4px;
}

.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, rgba(195, 195, 195, 0)),
        color-stop(20%, rgba(195, 195, 195, 0.2)),
        color-stop(60%, rgba(195, 195, 195, 0.5)),
        to(rgba(195, 195, 195, 0))
    );
    background-image: -o-linear-gradient(
        left,
        rgba(195, 195, 195, 0) 0,
        rgba(195, 195, 195, 0.2) 20%,
        rgba(195, 195, 195, 0.5) 60%,
        rgba(195, 195, 195, 0)
    );
    background-image: linear-gradient(
        90deg,
        rgba(195, 195, 195, 0) 0,
        rgba(195, 195, 195, 0.2) 20%,
        rgba(195, 195, 195, 0.5) 60%,
        rgba(195, 195, 195, 0)
    );
    -webkit-animation: shimmer 3s infinite;
    animation: shimmer 3s infinite;
    content: "";
}

@-webkit-keyframes shimmer {
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@keyframes shimmer {
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}
